<template>
  <v-layout column>
    <div class="align-center text-center" style="margin-top: 10px">
      <v-card elevation="6" max-width="800" style="margin: auto">
        <v-toolbar flat dense class="blue darken-1" dark>
          <v-toolbar-title>{{ $t('tables.personalInfo') }}</v-toolbar-title>
        </v-toolbar>
        <!--class that defines paddings(left, right, top && bottom-->
        <div class="pl-4 pr-4 pt-2 pb-4">
          <form name="register-form" autocomplete="off">
            <v-layout row class="mx-0 mt-3">
              <v-flex>
                <v-text-field
                  v-model="firstName"
                  :label="$t('global.firstName')"
                  filled
                ></v-text-field>
              </v-flex>
            </v-layout>

            <v-layout row class="mx-0">
              <v-flex>
                <v-text-field
                  v-model="lastName"
                  :label="$t('global.lastName')"
                  filled
                ></v-text-field>
              </v-flex>
            </v-layout>

            <v-layout row class="mx-0">
              <v-flex class="md7">
                <v-text-field
                  class=""
                  v-model="address"
                  :label="$t('global.address')"
                  filled
                ></v-text-field>
              </v-flex>
              <v-flex class="md4 ml-16">
                <v-text-field
                  class=""
                  v-model="zipCode"
                  :label="$t('global.zipCode')"
                  filled
                ></v-text-field>
              </v-flex>
            </v-layout>

            <v-layout row class="mx-0">
              <v-flex>
                <v-text-field
                  v-model="nationality"
                  :label="$t('global.nationality')"
                  filled
                ></v-text-field>
              </v-flex>
            </v-layout>

            <v-layout row class="mx-0">
              <v-flex>
                <v-text-field v-model="nif" :label="$t('global.nif')" filled></v-text-field>
              </v-flex>
            </v-layout>

            <v-layout row class="mx-0">
              <v-flex class="md7">
                <v-text-field
                  v-model="billingNumber"
                  label="Billing Card Number"
                  filled
                ></v-text-field>
              </v-flex>
              <v-flex class="md2 ml-8">
                <v-text-field v-model="csv" label="CSV" filled></v-text-field>
              </v-flex>
              <v-flex class="md2 ml-8">
                <v-text-field
                  v-model="expireDate"
                  label="Expire Date"
                  filled
                ></v-text-field>
              </v-flex>
            </v-layout>
          </form>
          <br />

          <v-btn elevation="2" @click="updateUserInfo"> {{ $t('button.save') }} </v-btn>
        </div>
        <br />
        <div class="red--text" v-html="error" />
        <div class="green--text" v-html="success" />
      </v-card>
    </div>
  </v-layout>
</template>

<script>
import UserService from "@/services/UserService";

export default {
  data() {
    return {
      firstName: "",
      lastName: "",
      address: "",
      zipCode: "",
      nationality: "",
      nif: "",
      billingNumber: "",
      csv: "",
      expireDate: "",
      error: null,
      success: null,
    };
  },
  methods: {
    async updateUserInfo() {
      try {
        const response = await UserService.updateUserInfo({
          user: this.$store.getters.userId,
          firstName: this.firstName,
          lastName: this.lastName,
          address: this.address,
          zipCode: this.zipCode,
          nationality: this.nationality,
          nif: this.nif,
        });
        this.error = null;
        this.success = "User info successfully updated."
        console.log("response: ", response);
      } catch (error) {
        this.error = error.response.data.error;
      }
    },
  },
};
</script>

<style scoped>
</style>